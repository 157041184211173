.App {
  text-align: center;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background-color: #1f2937; */
  background-color: #f4f4f4;
}
.quizContainer {
  width: 70%;
  height: 40rem;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}
.leftContainer {
  position: relative;
  width: 50%;
  background-color: #374151;
  text-align: left;
}
.qNumber {
  position: absolute;
  bottom: 0;
  background-color: rgb(253, 238, 144);
  width: 4rem;
  height: 2.3rem;
  border-top-right-radius: 10px;
  display: grid;
  place-items: center;
  font-size: 1.1rem;
}
.leftContainerInner {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem 1rem;
  color: white;
}
.leftContainerInner > h1 {
  font-size: 1.2rem;
  font-weight: 300;
}
.leftContainerInner > h3 {
  margin-top: 10px;
  font-weight: 500;
}
.rightContainer {
  background-color: #111827;
  width: 50%;
}
.rightContainerInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}
.options {
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column-reverse;
  grid-gap: 10px;
}
.option {
  list-style: none;
  border: 1px solid rgb(232, 224, 255);
  border-radius: 10px;
  padding: 1rem;
  text-align: left;
  color: rgb(232, 224, 255);
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.activeOption {
  background-color: rgb(232, 224, 255);
  color: black;
}
.option:hover {
  background-color: rgb(232, 224, 255);
  cursor: pointer;
  color: black;
}
.actionButtons {
  display: flex;
  grid-gap: 10px;
  justify-content: center;
}
.btn {
  background-color: transparent;
  font: inherit;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  border: 2px solid rgb(182, 133, 255);
  color: rgb(182, 133, 255);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
}
.btn-primary {
  background-color: rgb(182, 133, 255);
  color: white;
  border: none;
}
.btn-disable {
  opacity: 0.6;
  cursor: no-drop;
}
.radioCheckIcon {
  width: 1rem;
}
.activeOption .radioCheckIcon {
  color: #111827;
}

@media screen and (max-width: 768px) {
  .quizContainer {
    width: 90%;
    height: auto;
    flex-direction: column;
  }
  .qNumber {
    top: 0;
    right: 0;
    border-bottom-left-radius: 10px;
  }
  .leftContainer,
  .rightContainer {
    width: 100%;
  }
  .leftContainer {
    height: auto;
  }
  .rightContainer {
    height: auto;
  }
  .rightContainerInner {
    height: auto;
  }
  .actionButtons {
    margin: 0 1rem 1rem;
  }
  .resultPopup {
    width: 85%;
    height: 90%;
  }
}

.btn-download-pdf {
  position: relative;
  width: 156px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #17795e;
  background-color: #209978;
  overflow: hidden;
}

.btn-download-pdf,
.btn-download-pdf__icon,
.btn-download-pdf__text {
  transition: all 0.3s;
}

.btn-download-pdf .btn-download-pdf__text {
  transform: translateX(15px);
  color: #fff;
  font-weight: 600;
}

.btn-download-pdf .btn-download-pdf__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: #17795e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-download-pdf .svg {
  width: 20px;
  fill: #fff;
}

.btn-download-pdf:hover {
  background: #17795e;
}

.btn-download-pdf:hover .btn-download-pdf__text {
  color: transparent;
}

.btn-download-pdf:hover .btn-download-pdf__icon {
  width: 148px;
  transform: translateX(0);
}

.btn-download-pdf:active .btn-download-pdf__icon {
  background-color: #146c54;
}

.btn-download-pdf:active {
  border: 1px solid #146c54;
}
